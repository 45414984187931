
/*=============================================
=            project gallery            =
=============================================*/

.project-gallery-wrapper{
    margin-bottom: -30px;

    .col-mobile-6{

        @media #{$large-mobile}{
            flex: 0 0 50%;
            max-width: 50%;
        }

        @media #{$extra-small-mobile}{
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}

.single-gallery-project{
    position: relative;
    margin-bottom: 30px;

    &:hover{
        .single-gallery-project__content{
            visibility: visible;
            opacity: 1;
            bottom: 20px;
        }   
    }

    &__image{
        img{
            width: 100%;
        }
    }

    &__content{
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 20px;
        padding: 20px;
        z-index: 1;
        visibility: hidden;
        opacity: 0;
        transition: 0.3s;
        max-width: 220px;

        @media #{$desktop-device}{
            max-width: 170px;
        }

        @media #{$tablet-device}{
            visibility: visible;
            opacity: 1;
            bottom: 20px;
            left: 10px;
            max-width: 130px;
        }

        @media #{$large-mobile}{
            visibility: visible;
            opacity: 1;
            bottom: 20px;
        }
        
        @media #{$small-mobile}{
            visibility: visible;
            opacity: 1;
            left: 10px;
            max-width: 170px;
        }

        @media #{$extra-small-mobile}{
            max-width: 300px;
        }
        
        .title{
            font-size: 16px;
            line-height: 1.3;
            letter-spacing: 1px;
            color: #fff;
            margin-bottom: 0;

            @media #{$tablet-device}{
                font-size: 12px;
            }
        }

        a{
            font-size: 13px;
            line-height: 1.3;
            color: $white;
            text-decoration: underline;
        }

        &:after{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: "";
            background: $theme-color--default;
            opacity: 0.6;
            z-index: -1;
        }
    }

   
}

/*=====  End of project gallery  ======*/

